import React from 'react'
import './vault.css';
import cart from './cart.png';
import { Col, Container, Row } from 'reactstrap';
import Lottie from 'react-lottie';
import animationData from '../../lotties/2.json';
import PRVpydex from './PRVpydex.gif';
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

const Vault = () => {
    const navigate  = (url) =>{
        window.location.href = url;
    }
    return (
        <Container style={{paddingTop:20,paddingBottom:20}}>
              <h1 style={{ fontSize: "3rem" }} className="Ahead">Features <div style={{ color: "#F3BA2F" }}></div></h1>
            <hr/>
            <br />
            <Row>
                <Col xl="6" xs="12">
              
                    <h1 style={{ display: "flex" }}>PY<div style={{ color: "#F3BA2F" }}>DEX</div></h1>
                    <div style={{ fontSize: "1.2rem" }} >
                        <p>PYDEX is our ecosystem under the DEX layer. We at PrivacySwap want to make the DEX, farms, and pools more useful by combining them. You'll be able to trade and stake your tokens on a single platform. Under this new ecosystem, we have also strengthened our utility and built more revenue streams.
                            <div>PYDEX is also the new ticker for the platform's tokens. Staking, trading, and hodling will be possible with this new utility token. The power of our old PRVG and PRV2 tokens is now at  PYDEX.</div>
                            
                        </p>
                        
                        <button type="button" 
                    onClick={()=>{
                        navigate("https://pydex.privacyswap.finance/")
                    }}
                    className="Nbtn2">Explore </button>
                    </div>
                </Col>
                <Col xl="6" xs="12">
                    {/* <div style={{background:"/images/overlay.png"}}>
                        <img src="/gifs/vaults.gif" alt="cart Image"  
                        style={{background:"url(/images/overlay.png)",
                        "background-repeat": "round",
                        "background-size" :"contain"}}
                        width={500} height={500} />


                        
                    </div> */}

<img style={{"margin-top" : "-10%", "width" : "80%"}} src={PRVpydex} alt="Logo" />
                </Col>
            </Row>
            <div >
                {/* <h1 style={{ display: "flex" }}>Privacy<div style={{ color: "#F3BA2F" }}>Vault</div></h1>
                <div >
                    <p>PrivacySwap’s principal offering. We aim to provide
                        <div>our dear users a sustainable and consistent way to</div>
                        <div>earn on their assets. Our PrivacyVaults operate</div>
                        <div>operate under the principle of Yield Optimization, which uses</div>
                        <div>an auto compounding process to optimize yield.</div>
                        <div>Therefore, the vaults are optimized for maximum earnings.</div>
                        <div>We are also adding higher risk, higher APY vaults.</div>
                    </p>
                    <p>You read that right. Through our PrivacyVaults, we
                        <div>do the heavy-lifting for you.</div>
                    </p>
                </div> */}
                {/* <div className="Vmobile">
            <p style={{fontSize:"0.9rem"}}>PrivacySwap’s principal offering. We aim to provide 
                our dear users a sustainable and consistent way to
                earn on their assets. Our PrivacyVaults operate
                operate under the principle of Yield Optimization, which uses
                an auto compounding process to optimize yield.
                Therefore, the vaults are optimized for maximum earnings.
                We are also adding higher risk, higher APY vaults.
            </p>
            <p style={{fontSize:"0.9rem"}}>You read that right. Through our PrivacyVaults, we 
                do the heavy-lifting for you.
            </p>
            </div> */}
                {/* <h4 style={{ color: "#F3BA2F" }}>Explore More</h4> */}
            </div>
            {/* <div>
                <img src={cart} alt="cart Image" className="CImg" />
            </div> */}
        </Container>
    )
}

export default Vault;
