import React from 'react'
import { Container } from 'reactstrap';
import './About.css';
import AboutImg from './PRV.PNG.png';
const About = () => {
    return (
        <Container style={{ marginBottom:"50px"}}>
			<div className="row">
				<div className="col-12 col-xl-12">
					<h1 style={{ textAlign:"center" }}>About<div style={{ color: "#F3BA2F",display: "inline-block" }}>Us</div></h1>
					
					 
					 
					 
					<p style={{ fontSize: "1.1rem" }} > PrivacySwap is an ecosystem for yield farming created on the Binance Smart Chain (BSC) by a team of cybersecurity experts. Combining on-chain data with conventional off-chain services, we have evolved to become the first digital assets web3 wallet oracle and aggregator for all DeFi projects. With the rise of blockchain and cryptocurrencies, as well as the rise of decentralized ecosystems, PrivacySwap intends to give additional utility to strengthen the usability and integrity of not only DeFi and blockchain, but also cyberspace. first digital assets web3 wallet oracle and aggregator for all DeFi projects, combining on-chain data with traditional off-chain services. With blockchain and cryptocurrency being acknowledged as the way forward, along with the uprising of DeFi and decentralized ecosystems, PrivacySwap aims to insert a layer of privacy protection to bolster the security and integrity of not only DeFi and blockchain, but cyberspace in general.</p>

					<p style={{ fontSize: "1.1rem" }} >With our move towards PYDEX, we bring along our philosophy from the first layer: to provide utility and promote crypto security for all users.</p>

					

					 
					 
					 
				</div>
				<div className="col-12 col-xl-12">
					<img style={{paddingTop:40}} className="img-fluid" src={AboutImg} alt="Card" />
				</div>
			</div>
		
		
           

        </Container>
    )
}

export default About
