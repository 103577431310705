import React from 'react'
import './App.css';
import NavBar from './components/NavBar/NavBar';
import Home from './components/Home/Home';
import Features from './components/Features/Features';
import About from './components/About/About';
import Vault from './components/Privacy/PrivacyVault/Vault';
import Card from './components/Privacy/PrivacyCard/Card';
import Mixer from './components/Privacy/PrivacyMixer/Mixer';
import HackerNet from './components/Privacy/HackerNet/HackerNet';
import Game from './components/Privacy/PrivacyGame/Game';
import Featur from './components/Featur/Featur';
import Roadmap from "./components/Roadmap/Roadmap";
import Faq from './components/Faq/Faq';
import { Switch, Route, Router } from 'react-router-dom';

const App = () => {
  return (
    <div>
      <NavBar />
      <Home />
      <Features />
      <About />

      <div id="Features">

      <Vault />
      <Card />
      <Mixer />
      <HackerNet />
      <Game />
      </div>
      {/* <Featur /> */}
      <Roadmap />
      <Faq />
    </div>
  )
}

export default App
