import React from 'react'
import { Col, Container, Row } from 'reactstrap';
import './Card.css';
import girl from './girl.png';
import CardImg from './PRV-website privacycards.gif';

import Lottie from 'react-lottie';
import animationData from '../../lotties/3.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


const Card = () => {
    const navigate  = (url) =>{
        window.location.href = url;
    }
    return (
        <Container style={{paddingTop:20,paddingBottom:20}}>
            <Row>
                <Col xl="6" xs="12">
                    {/* <img src={girl} alt="girlImage" width="100%" /> */}
                    {/* <img src="/gifs/cards.gif" alt="cart Image"  
                        style={{
                            // background:"url(/images/overlay.png)",
                        "background-repeat": "round",
                        "background-size" :"contain"}}
                        width={500} height={500} />   <Lottie 
	    options={defaultOptions}
        height={400}
        width={400}
      />*/}
               <img style={{"margin-top" : "-10%", "width" : "80%"}} className="img-fluid" src={CardImg} alt="Card" />	        
                </Col>
                <Col>
                    <h1 style={{ display: "flex" }}>Privacy<div style={{ color: "#F3BA2F" }}>Card</div></h1>
                    <div style={{ fontSize: "1.2rem" }}>
                        <p >PrivacyCard is the platform’s main feature and serves as the answer to the debit card system. Its goal is to bridge cryptocurrency and fiat transactions for all our users. Combining utility and web3.0’s security, PrivacyCard runs under the PrivacyOracle technology, the world's first web3 card loading platform that lets you connect your card directly to your MetaMask.
                            <div>With PrivacyCard, manage your crypto holdings seamlessly and get cashless transactions.</div>
                            
                        </p>
                       </div>
                        <button type="button" 
                    onClick={()=>{
                        navigate("https://cards.privacyswap.finance/")
                    }}
                    className="Nbtn2">Explore </button>
                </Col>
            </Row>
        </Container>
        // <div className="Hero">
        //     <div>
        //         <img src={girl} alt="girlImage" className="Gimage" />
        //     </div>
        //     <div className="CRight">
        //         <h1 style={{display:"flex"}}>Privacy<div style={{color:"#F3BA2F"}}>Card</div></h1>
        //         <div className="Cpc">
        //         <p >PrivacySwap’s answer to the debit card system, the 
        //             <div>intended bridge from crypto fiat for all our users.</div>
        //             <div>With privacy at its core, PrivacyCard is THE solution</div>
        //             <div>for users to spend their PRV and other tokens</div>
        //             <div>conveniently in real-world transactions everywhere.</div>
        //         </p>
        //         <p >Got accumulating tokens? Let PrivacyCard translate
        //             <div>them to usage.</div> 
        //         </p>
        //         </div>
        //         <div className="CMobile">
        //         <p style={{fontSize:"0.9rem"}}>PrivacySwap’s answer to the debit card system, the 
        //             intended bridge from crypto fiat for all our users.
        //             With privacy at its core, PrivacyCard is THE solution
        //             for users to spend their PRV and other tokens
        //             conveniently in real-world transactions everywhere.
        //         </p>
        //         <p style={{fontSize:"0.9rem"}}>Got accumulating tokens? Let PrivacyCard translate
        //             them to usage.
        //         </p>
        //         </div>
        //         <h3 style={{color:"#F3BA2F"}}>Explore More</h3>
        //     </div>
        // </div>
    )
}

export default Card
