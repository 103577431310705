import React from 'react'
import { Col, Container, Row } from 'reactstrap';
import './Game.css';
import gameimg from './gameimg.png'

import Lottie from 'react-lottie';
import animationData from '../../lotties/6.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


const Game = () => {
    const navigate  = (url) =>{
        window.location.href = url;
    }
    return (
	<></>
        
        // <div className="Hero"> 
        //     <div className="GLeft">
        //         <h1 style={{display:"flex"}}>Privacy<div style={{color:"#F3BA2F"}}>Games</div></h1>
        //         <p style={{fontSize:"0.9rem"}}>We can’t always be too serious - people want to use 
        //             <div>their PRV tokens for games, too!</div>
        //         </p>
        //         <p style={{fontSize:"0.9rem"}}>We understand that it’s not for everyone, but we
        //             <div>figure that it would be a nice idea to let people put</div>
        //             <div>their PRV down for a chance at winning some more</div>
        //             <div>PRV!</div>
        //         </p>
        //         <p style={{fontSize:"0.9rem"}}>Be it if you like taking your chances or if you just
        //             <div>want to buy a little tinge of hope of a pot of PRV, we</div>
        //             <div>  are sure that our PrivacyGames will keep you </div>
        //             <div>somewhat occupied!</div>
        //         </p>
        //         <h3 style={{color:"#F3BA2F"}}>Explore More</h3>
        //     </div>
        //     <div>
        //         <img src={gameimg} alt="playingImage" className="GImg" />
        //     </div>
        // </div>
    )
}

export default Game
