import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import './HackerNet.css';
import net from './PRVlaunchpad.gif';

import Lottie from 'react-lottie';
import animationData from '../../lotties/5.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


const HackerNet = () => {
    const navigate  = (url) =>{
        window.location.href = url;
    }
    return (
        <Container style={{paddingTop:50,paddingBottom:50}}>
            <Row>
                
                <Col xl="6" xs="12">
                    <h1 style={{ display: "flex" }}>Privacy<div style={{ color: "#F3BA2F" }}>Launchpad</div><div style={{ paddingLeft: "1rem" }}></div></h1>
                    <div>
                        <p style={{fontSize:"1.3rem"}} >WhaTo uplift others is part of what we do. We aim that all startups will have their projects heard. Additionally, we want to maximize earning ways in crypto. That’s possible through PrivacyLaunchpad.   Share your project's initial offerings with us and our PYDEX users will help you grow.
                            
                        </p>


                {/* <button type="button" 
                    onClick={()=>{
                        navigate("https://app.privacyswap.finance/")
                    }}
                    className="Nbtn2">Explore </button> */}
					<button type="button" 
                    onClick={()=>{
                        navigate("https://cards.privacyswap.finance/")
                    }}
                    className="Nbtn2">Explore </button>
                    </div>
                </Col>
				<Col xl="6" xs="12">
                    {/* <img src={net} alt="InternetImage" /> */}
                    {/* <img src="/gifs/marketplace.gif" alt="cart Image"  
                        style={{
                            // background:"url(/images/overlay.png)",
                        "background-repeat": "round",
                        "background-size" :"contain"}}
                        width={500} height={500} /> */}
                          <img style={{"margin-top" : "-10%", "width" : "80%"}} className="img-fluid" src={net} alt="Card" />
                </Col>
            </Row>
        </Container>
        // <section className="Hero">
        //      <div>
        //         <img src={net} alt="InternetImage" className="HImage" />
        //     </div>
        //     <div className="HLeft">
        //         <h1 style={{display:"flex"}}>Hacker<div style={{color:"#F3BA2F"}}>Net</div><div style={{paddingLeft:"1rem"}}>Marketplace</div></h1>
        //         <div className="Hpc">
        //         <p >What can be better than Fiverr? A decentralized
        //             <div> marketplace where people can acquire services </div>
        //             <div>from curated whitehats, smart contract auditors, or</div>
        //             <div> bug bounty. Curation is achieved through client</div>
        //             <div>reviews and evident track record.</div> 
        //         </p>
        //         <p >Legitimate services by legitimate professionals.
        //             <div> Welcome to HackerNet.</div>
        //         </p>
        //         </div>
        //         <div className="HMobile">
        //         <p style={{fontSize:"0.9rem"}}>What can be better than Fiverr? A decentralized
        //              marketplace where people can acquire services 
        //             from curated whitehats, smart contract auditors,or
        //              bug bounty. Curation is achieved through client
        //             reviews and evident track record. 
        //         </p>
        //         <p style={{fontSize:"0.9rem"}}>Legitimate services by legitimate professionals.
        //              Welcome to HackerNet.
        //         </p>
        //         </div>
        //         <h3 style={{color:"#F3BA2F"}}>Explore More</h3>
        //     </div>
        // </section>
    )
}

export default HackerNet
