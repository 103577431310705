import React from 'react'
import './Mixer.css';
import man from './man.png';
import { CardImg, Col, Container, Row } from 'reactstrap';

import Lottie from 'react-lottie';
import animationData from '../../lotties/4.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


const Mixer = () => {
    const navigate  = (url) =>{
        window.location.href = url;
    }
    return (
        <Container>
            <Row>
               {/* <Col xl="6" xs="12">
                   
                        <h1 style={{ display: "flex" }}>Privacy<div style={{ color: "#F3BA2F" }}>Mixer</div></h1>

                        <p style={{fontSize:"1.2rem"}} >How can you say that the blockchain is safe, secure,
                            <div>and private when I know exactly how much you had</div>
                            <div> and have the moment you send me funds?</div>
                        </p>
                        <p style={{fontSize:"1.2rem"}}>Banks don’t reveal your statements to recipients
                            <div>when you send or receive funds, so why should the</div>
                            <div>blockchain--touted to be safer and more secure--allow that?</div>
                        </p>
                        <p style={{fontSize:"1.2rem"}}>Where possible, the immutability of the blockchain is
                            <div>amazing.But when it comes to some of your</div>
                            <div> transactions, you should be given the option to</div>
                            <div> maintain your privacy.</div>
                        </p>
                        <p style={{fontSize:"1.2rem"}}>The PrivacyMixer is the answer to that.</p>
                    
                        <button type="button" 
                    onClick={()=>{
                        navigate("https://docs.privacyswap.finance/features/privacymixer")
                    }}
                    className="Nbtn2">Explore </button>
                </Col>
                <Col xl="6" xs="12">
                <Lottie 
	    options={defaultOptions}
        height={400}
        width={400}
      />
                </Col>*/}
            </Row>
        </Container>
        // <div className="Hero">
        //     <div className="MLeft">
        // <h1 style={{display:"flex"}}>Privacy<div style={{color:"#F3BA2F"}}>Mixer</div></h1>
        // <div className="Mpc">
        // <p >How can you say that the blockchain is safe, secure,
        //     <div>and private when I know exactly how much you had</div> 
        //     <div> and have the moment you send me funds?</div>
        // </p>
        // <p >Banks don’t reveal your statements to recipients 
        //     <div>when you send or receive funds, so why should the</div>
        //     <div>blockchain--touted to be safer and more secure--allow that?</div>
        // </p>
        // <p >Where possible, the immutability of the blockchain is 
        //     <div>amazing. But when it comes to some of your</div>
        //     <div> transactions, you should be given the option to</div>
        //     <div> maintain your privacy.</div>
        // </p>
        // <p >The PrivacyMixer is the answer to that.</p>
        //     </div>
        //     <div className="MMobile">
        //     <p style={{fontSize:"0.9rem"}}>How can you say that the blockchain is safe, secure,
        //         and private when I know exactly how much you had 
        //         and have the moment you send me funds?
        //     </p>
        //     <p style={{fontSize:"0.9rem"}} >Banks don’t reveal your statements to recipients 
        //         when you send or receive funds, so why should the
        //         blockchain--touted to be safer and more secure--allow that?
        //     </p>
        //     <p style={{fontSize:"0.9rem"}}>Where possible, the immutability of the blockchain is 
        //         amazing. But when it comes to some of your
        //          transactions, you should be given the option to
        //          maintain your privacy.
        //     </p>
        //     <p style={{fontSize:"0.9rem"}}>The PrivacyMixer is the answer to that.</p>
        //     </div>
        //     <h3 style={{color:"#F3BA2F"}}>Explore More</h3>
        //     </div>
        //     <div>
        //         <img src={man} alt='manImage' className="MImg" />
        //     </div>
        // </div>
    )
}

export default Mixer
