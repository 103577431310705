import React from 'react'
import { Container } from 'reactstrap';
import './Roadmap.css';
import './Roadmap.css';
import RoadmapImg from './Roadmap1.png';
const Roadmap = () => {
    return (
        <Container className="Road" id="Road">
            <h1 style={{textAlign:"center"}}>Our Roadmap</h1>
            <hr/>
            <br />
            {/*<h3 style={{textAlign:"center", marginTop:"40px", color:"#F3BA2F"}}>
                "Update in Progress"
            </h3>*/}
			<img className="img-fluid" src={RoadmapImg} alt="Roadmap" />	

            {/* <div className="Rcontainer">
                <div className="Ritems">
                <p>Seed</p>
                <p>Bonsai</p>
                <p>Tree</p>
                <p>Baobab</p>
                </div>
                <div className="RLine"></div>
            </div> */}
            {/* <div className="RightHeroR">
                <div className="RU">
                <button type="button" className="Rbtn">Multilisting</button>
                <button type="button" className="Rbtn">Price Bot</button>
                <button type="button" className="Rbtn">Light Audit</button>
                <button type="button" className="Rbtn">Multilisting</button>
                <button type="button" className="Rbtn">Multilisting</button>
                </div>
                <div className="RM">
                <button type="button" className="Rbtn">Lottery</button>
                <button type="button" className="Rbtn">Multilisting</button>
                <button type="button" className="Rbtn">Multilisting</button>
                <button type="button" className="Rbtn">Multilisting</button>
                <button type="button" className="Rbtn">Multilisting</button>
                </div>
                <div className="RD">
                <button type="button" className="Rbbtn">Twitter Daily Updates</button>
                <button type="button" className="Rbbtn">Twitter Contest</button>
                <button type="button" className="Rbbtn">Planting Real Trees</button>
                </div>
            </div> */}
        </Container>
    )
}

export default Roadmap
