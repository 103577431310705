import React, { useState } from "react";
import "./Faq.css";
import m from "./m.png";
import twitter from "./twitter.png";
import discord from "./discord.png";
import youtube from "./youtube.png";
import telegram from "./telegram.png";
import { Container } from "reactstrap";
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import ListGroupCollapse from './ListGroupCollapse';

const things = {
  a: {
    que: 'What is PrivacySwap?',
    ans: 'We are a dApp that focuses on privacy. We are built on the Binance Smart Chain, and are working to put out a suite of privacy-centric solutions for users. PrivacySwap is your gateway to earning while keeping your privacy to whom it belongs to - you. Read more at <a class="links" target="_blank" href="https://docs.privacyswap.finance/" >https://docs.privacyswap.finance/</a>'
  },
  b: {
    que: 'What is Yield Farming?',
    ans: 'Yield Farming is the process of requiring the user to stake a certain amount of tokens in liquidity, which is then transferred across several DeFi platforms to optimize the return. By doing so, users are rewarded with LP tokens. In PrivacySwap, yield farming is secure as your tokens are safeguarded in vaults. Each of these vaults has its unique yield-increment strategy, which is detailed in the smart contract code. The beauty of these vaults is that they are set up to reinvest your money to boost your returns automatically.'
  },
  c: {
    que: 'How do I use PrivacySwap?',
    ans: 'We aim for the PrivacySwap platform to be user-friendly to all. If you are relatively new to DeFi, we’ve covered the necessary steps you need to take to begin your PRV journey. Read more about it  <a target="_blank" class="links" href=" https://docs.privacyswap.finance/guides/start-here " > here </a>'
  },
  d: {
    que: 'Is it safe to use PrivacySwap?',
    ans: 'The PrivacySwap ecosystem is anchored in cybersecurity and privacy, developed by founders who are actively serving as cybersecurity professionals. We’ve taken measures to ensure this, such as being clear on the following: no founder tokens, no presale, no rugpull migrator code, and fair launch on PancakeSwap. Our smart contracts were also initially audited by TechRate, and are currently undergoing auditing with CertiK. With all of these security measures in place, we continue to harness a culture of DYOR (Do Your Own Research) among our community. We welcome any enquiries pertaining to the safety of the ecosystem.'
  },
  e: {
    que: 'What are tokens?',
    ans: 'Tokens are DeFi applications running on blockchains, echoing concepts that are successfully used in traditional finance. For PrivacySwap, the current utility token is called PRV. Users will stake their PRVs to earn rewards, concurrently enabling developers to earn a slight percentage of those rewards to push forward the goal of privacy on the blockchain and DeFi. In the second ecosystem (aka “The Great Migration”), PrivacySwap users will have the opportunity to hold two tokens: PRVG (Governance token), and PRV2 (the farming token).'
  },
  f: {
    que: 'What is The Great Migration?',
    ans: 'The Great Migration for PrivacySwap is the transition from the current PRV ecosystem to PRV 2.0. This move to the second layer is intended to provide a better and bigger ecosystem for our users, which include high APR farms and a slew of products. To learn more about this new ecosystem, head on to our Gitbook page: <<link to be provided soon>>'
  },
}
	


const Faq = () => {
 
  const navigate  = (url) =>{
    window.location.href = url;
}
  return (
    <>
      <Container className="Faq" id="Fa">
        <div style={{ textAlign: "center" }}>
          <h1>FAQ</h1>
            <hr/>
            <br />
          <p className="Fapara">
            Here are some of the questions we recieve frequently.If you have
            ulterior questions contact us through our Telegram channel
          </p>
        </div>
		<div className="FboxContainer">
			{Object.keys(things).map(key =>
			  <ListGroupCollapse key={key} cat={things[key]} />
			)}
		</div>
			
        
        <div className="FLast">
          <div
            style={{
              width: "15.7rem",
              display: "flex",
              cursor:"pointer",
              justifyContent: "space-between",
            }}
          >
            Any other questions?
            <a style={{ color: "#F3BA2F" ,cursor:"pointer"}} onClick={()=>{
              navigate("https://t.me/PrivacySwap")
            }}> Get in Touch</a>
          </div>
        </div>
      </Container>
      <footer className="Footer">
        <div className="bc">
          <div className="icon">
            <div className="iconC">
              <img src={m} className="smallImage" 
              onClick={()=>{
                navigate("https://privacyswap.medium.com");
              }}
              style={{cursor:"pointer"}} />

              <img src={twitter} className="smallImage"
                onClick={()=>{
                  navigate("https://twitter.com/PrivacySwap");
                }}
                style={{cursor:"pointer"}} />

              <img src={discord} className="smallImage"
                onClick={()=>{
                  navigate("https://www.reddit.com/r/privacyswap/");
                }}
                style={{cursor:"pointer"}} />

              <img src={youtube} className="smallImage"
                onClick={()=>{
                  navigate("https://www.youtube.com/channel/UCH9bH2LGSc9b4lCC62luUwg");
                }}
                style={{cursor:"pointer"}} />

              <img src={telegram} className="smallImage"
                onClick={()=>{
                  navigate("https://t.me/PrivacySwap");
                }}
                style={{cursor:"pointer"}} />
            </div>
            <p style={{ background: "transparent", paddingTop: "1rem" }}>
              © 2021 PrivacySwap. All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Faq;
