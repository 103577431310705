import React from "react";
import { CardImg, Col, Container, Row } from "reactstrap";
import "./Home.css";
import home from "./home.png";
import home_banner from "./home_banner.png";
import Lottie from "react-lottie";
import animationData from "../lotties/1.json";

const navigate = (url) => {
  window.location.href = url;
};
const navigate_new = (url) => {
  window.open(url, '_blank');
};

const Home = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Container
      id="Home"
      style={{
        paddingTop: "150px",
        paddingLeft: "20px",
        paddingRight: "20px",
        marginBottom: "50px",
      }}
    >
      <Row>
        <Col xl="6" xs="12">
          <h1 style={{ fontSize: "2.5rem" }}>
            {" "}
            <div>The all-in-one DEX platform on BSC with a web3 crypto loading platform</div>
            <div style={{ display: "flex" }}>
crypto              <div style={{ color: "#F3BA2F", paddingLeft: "0.8rem" }}>
                loading platform
              </div>
            </div>
          </h1>
          <p style={{ fontSize: "1.1em" }}>
            Enjoy earning with yield farming and other utilities, 
            <div>
              plus exchanging with the lowest fees in DeFi!
            </div>
          </p>
          <div>
            <button
              type="button"
              className="Hbtn1"
              onClick={() => {
                navigate("https://docs.privacyswap.finance/");
              }}
            >
              Gitbook
            </button>
            <button
              type="button"
              className="Hbtn2"
              onClick={() => {
                navigate("https://pydex.privacyswap.finance/");
              }}
            >
              Launch PYDEX
            </button>
			<button
              type="button"
              className="Hbtn12"
              onClick={() => {
                navigate_new("https://drive.google.com/file/d/1WR0MVvrcA02uyg-8A-jp5RPdhRwiQN4I/view?usp=sharing");
              }}
            >
              Get Brochure
            </button>
          </div>
        </Col>
        <Col xl="6" xs="12">
		<img className="img-fluid" src={home_banner} alt="hero image" width="100%" />
          {/* <CardImg src={home} /> <Lottie options={defaultOptions} height={400} width={400} /> */}
          

          {/* <div>
                        <img src={home} alt="hero image" width="100%" />
                    </div> */}
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
