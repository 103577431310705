import React from 'react'
import './Features.css';
import coin1 from './icon/coin1.png';
import Business from './icon/Business.png';
import dapp from './icon/dapp.png';
import coin2 from './icon/coin2.png'
import rugdoc from './icon/rugdoc.png';
import yahoo from './icon/yahoo.png';
import { Col, Container, Row } from 'reactstrap';

const Features = () => {
    return (
        <Container style={{ marginBottom:"50px",paddingLeft:50,paddingRight:50}}>
            <Row>
                <h2 style={{textAlign:"center", fontSize:"2.8rem"}}>Featured In:</h2>
                <hr />
                <Col xl="4" xs="12" style={{verticalAlign:"middle",margin:"auto"}}>
                    <img src={coin1} alt="logo" style={{verticalAlign:"center", margin:"auto", display:"block"}} />
                </Col>
                <Col xl="4" xs="12" style={{verticalAlign:"center",margin:"auto"}}>
                    <img src={coin2} alt="logo" style={{verticalAlign:"center", margin:"auto", display:"block" }} />
                </Col>
                <Col xl="4" xs="12" style={{verticalAlign:"center",margin:"auto"}}>
                    <img src={dapp} alt="logo" style={{verticalAlign:"center", margin:"auto", display:"block" }} />
                </Col>
            </Row>
            <Row>
                {/* <h2 style={{textAlign:"center"}}>Featured In:</h2>
                <hr /> */}
                <Col xl="4" xs="12" style={{verticalAlign:"middle",margin:"auto"}}>
                    <img src={Business} alt="logo" style={{verticalAlign:"center", margin:"auto", display:"block"}} />
                </Col>
                <Col xl="4" xs="12" style={{verticalAlign:"center",margin:"auto"}}>
                    <img src={rugdoc} alt="logo" style={{verticalAlign:"center", margin:"auto", display:"block" }} />
                </Col>
                <Col xl="4" xs="12" style={{verticalAlign:"center",margin:"auto"}}>
                    <img src={yahoo} alt="logo" style={{verticalAlign:"center", margin:"auto", display:"block" }} />
                </Col>
            </Row>
            {/* <div className="Feature" id="Fea">
                <h2>Featured In:</h2>
                <div className="ImageLine" style={{marginBottom:"20px"}}><img src={coin1} alt="logo" style={{ width: "10rem" }} /> <img src={Business} alt="logo" style={{ width: "10rem" }} /> <img src={dapp} alt="logo" style={{ width: "10rem" }} /></div>
                <div className="ImageLine" style={{marginTop:"20px"}}><img src={coin2} alt="logo" style={{ width: "10rem" }} /> <img src={rugdoc} alt="logo" style={{ width: "10rem" }} /> <img src={yahoo} alt="logo" style={{ width: "10rem" }} /></div>
            </div> */}
        </Container>
    )
}

export default Features
