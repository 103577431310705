import React,{useState, useEffect, useRef } from 'react'
import './NavBar.css';
import icon from './icon.png';
import logo from './logo2.png';

let useClickOutside = (handler) =>{
    let domNode = useRef();

    useEffect( ()=>{
        let maybeHandler = (event) =>{
             if( !domNode.current.contains(event.target)){
                 handler();
             }
         }
         document.addEventListener("mousedown", maybeHandler);
    
         return ()=>{
            document.removeEventListener("mousedown", maybeHandler)
        }
     },[domNode] )
     return domNode
}


const NavBar = () => {
    const [ isMobile, setIsMobile] = useState(false); 

    const [ button, isButton ] = useState(false);

    let domNode = useClickOutside( ()=>{
        isButton(false)
    })   


    const navigate  = (url) =>{
         window.open(url, '_blank');
    }
    return (
        <nav className="nav">
                <a href="#Home" className="NLi"><div className="Logo"><img style={{"width" : "180px"}} src={logo} alt="Logo" /></div></a>
                <div className={(isMobile && button) ?"container1":"none"} ref={domNode}>
                <ul className="list">
                    <a href="#Home" className="NLi"><li href="">Home</li></a>
                    {/* <a href="#Pro" className="NLi"><li>Project</li></a> */}
                    <a href="#Features" className="NLi"><li>Features</li></a> 
                    <a href="#Road" className="NLi"><li>Roadmap</li></a> 
					<a href="https://cards.privacyswap.finance/" className="NLi"><li>Card</li></a> 
					<a href="https://blog.privacyswap.finance/" className="NLi"><li>Blog</li></a> 
                    {/* <a href="#New" className="NLi"><li>News</li></a>  */}
                    <a href="#Fa" className="NLi"><li>FAQ</li></a> 
                </ul> 
                <div className="Hbtna">
                    <button type="button" className="Nbtn1" onClick={()=>{
                        navigate("https://docs.privacyswap.finance/")
                    }}>  GitBook</button>
                    <button type="button" 
                    onClick={()=>{
                        navigate("https://pydex.privacyswap.finance/")
                    }}
                    className="Nbtn2">Launch App</button>
                </div>
                </div>
                <div className="hamburger"   onClick={()=> setIsMobile(!isMobile)} >
                    <div className="Line1" onClick={()=> isButton(!button)} ></div>
                    <div className="Line2"  onClick={()=> isButton(!button)}></div>
                    <div className="Line3" onClick={()=> isButton(!button)}></div>
                </div>
            </nav>   
    )
}

export default NavBar
