import React from 'react';
import { ListGroupItem, Collapse } from 'reactstrap';

class ListGroupCollapse extends React.Component {
  constructor(props) {
    super(props);
    
    this.toggle = this.toggle.bind(this);
    this.state = {collapse: false};
  }
  
  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }
  
  render() {
    const cat = this.props.cat;
    return (
     
        <>
          <div className="Fbox" onClick={this.toggle}>
            <p style={{ paddingLeft: "1rem" }}>{cat.que}</p> 
          </div>
          <Collapse className="Fbox"  isOpen={this.state.collapse}><p className="ans" dangerouslySetInnerHTML={{__html: cat.ans}} /></Collapse>
        </>
      
    );
  }
}

export default ListGroupCollapse